.tile-container {
    display: grid;
    grid-template-columns: repeat(5, 75px); /* Set the column width to match the tile width */
    grid-gap: 2px; /* Reduce the gap between tiles */
    justify-content: center; /* Center the grid horizontally */
    margin: 0 auto; /* Center the grid container */
  }
  .tile {
    transition: transform 0.3s ease;
  }
    
  .tile:hover {
     transform: scale(1.05) !important; 
  } 
  
  .tile-container-wrapper {
    padding: 20px;
    text-align: center; /* Center the wrapper */
  }

  .dialog-title {
    background-color: #f2994a;
    color: #fff;
    padding: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
  
  .dialog-content {
    padding: 16px;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 600; */
  }

  .dialog-content-2 {
    padding: 8px;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 600; */
  }
  
  .dialog-actions {
    padding: 16px;
  }

  .dialog-actions-bottom {
    padding: 16px;
    margin-bottom: 10%;
  }
  
  .label {
  font-weight: bold;
  margin-right: 5px;
}

.value {
  font-weight: normal;
  color: #333;
}

.button-hover:hover{
  background-color:#0093FB !important;
}

@media (min-width: 0px) {
  .tile {
    padding: 0;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    width: auto;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container-main{
    margin-top: 15% !important;
  }
  .page{
    margin-left: 1.5%;
  }
  .table-data-layout{
    height: fit-content;
    margin-right: 5% !important;
  }
  .s2{
    width: 100%;
    height: 100%;
  }
  .s6{
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .container-main{
    margin-top: 10% !important;
  }
  .table-data-layout{
    height: fit-content;
  }
  .seater{
    font-size: 26px !important;
  }
  .s2{
    width: 100%;
    height: 100%;
  }
  .s6{
    width: 100%;
    height: 100%;
  }
  .tile {
    padding: 0;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    width: auto;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
}
@media (min-width: 900px) {
  .container-main{
    margin-top: 8% !important; 
  }
  .table-data-layout{
    height: fit-content;
  }
  .s2{
    width: 78%;
    height: 78%;
  }
  .s6{
    width: 100%;
    height: 100%;
  }
  .tile {
    padding: 0;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    width: auto;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .container-main{
    margin-top: 2% !important; 
  }
  .table-data-layout{
    height: fit-content;
  }
  .seater{
    font-size: 30px !important;
  }
  .s2{
    width: 100%;
    height: 100%;
  }
  .s6{
    width: 100%;
    height: 100%;
  }
  .tile {
    padding: 0;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    width: auto;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}