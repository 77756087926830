  .message-box {
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .message-box.success {
    background-color: #4CAF50;
  }
  
  .message-box.error {
    background-color: #F44336;
  }
  
  .message-box.warning {
    background-color: #FF9800;
  }
  
  .message-box.info {
    background-color: #2196F3;
  }
  
  .close-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    position: absolute; /* Position the close button absolutely */
    top: -5px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
  }
  