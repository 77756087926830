.dialog-title {
    background-color: #f2994a;
    color: #fff;
    padding: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
  
  .dialog-content {
    padding: 24px;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 600; */
  }
  .dialog-content-2{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    height: auto;
    padding: 24px;
    font-family: 'Poppins', sans-serif;
  }
  
  
  