.dialog-title {
  background-color: #f2994a;
  color: #fff;
  padding: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.dialog-content {
  font-family: 'Poppins', sans-serif;
  /* font-weight: 600; */
}
.dialog-actions {
  padding: 16px;
}

.label {
font-weight: bold;
margin-right: 5px;
}
.label2 {
  font-weight: bold;
  padding-top: 10% !important;
  margin-left: 10%;
}
.value {
font-weight: normal;
color: #333;
}
.im-delete:hover{
cursor: pointer;
}
@media (max-width : 320px){
  #CategoryType{
    font-size: 12px;
  }
}
@media (min-width: 0px) {
.itemdata{
  font-size: 12px !important;
}
.cart{
  margin-top: 2% !important;
  margin-right: 2%;
  margin-bottom: 5% !important; 
}
.userDetails{
  margin-top: 22% !important;
}
.menudata{
  z-index: 1;
  height: max-content;
  margin-top: 14% !important;
  margin-left: 2% !important;
  margin-right: 2%;
}

.count{
  width: 10px;
  height: 10px;
}
.qm{
  width: 15px !important;
  height: 15px !important;
}
.ab{
  width: 25px;
  height: 25px;
}
.quantity{
  font-size: 15px !important;
}
.amount{
  font-size: 15px !important;
}
.total{
  font-size: 25px !important;
}
.note{
  width: 90% !important;
}
.cart-bottom{
  width: 21rem !important;
}
#CategoryType{
  font-size: 12px;
}
}
@media (min-width: 310px) {

.cart-bottom{
  width: 19rem !important;
}
.cart{
  margin-top: 2% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
}
}
@media (min-width: 375px) {
.total{
  font-size: 30px !important;
}
.quantity{
  font-size: 20px !important;
  padding-top: -30px;
}
.amount{
  font-size: 20px !important;
}
.qm{
  width: 20px !important;
  height: 20px !important;
}
.cart{
  margin-top: 2% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
}
.ab{
  width: 30px;
  height: 30px;
}
.cart-bottom{
  width: 25rem !important;
}
}
@media (min-width: 370px) {
.cart-bottom{
  width: 22rem !important;
}
}
@media (min-width: 455px) {
.menudata{
  z-index: 1;
  height: max-content;
  margin-top: 5% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
}
.userDetails{
  margin-top: 10% !important;
}
.im-delete{
  margin-left: 85% !important;
}
.total{
  font-size: 30px !important;
}
.quantity{
  font-size: 20px !important;
  padding-top: -30px;
}
.amount{
  font-size: 20px !important;
}
.qm{
  width: 25px !important;
  height: 25px !important;
}
.ab{
  width: 35px !important;
  height: 35px !important;
}
.cart{
  margin-top: 2% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
}
.cart-bottom{
  width: 45rem !important;
}
}
@media (min-width: 400px){
.cart-bottom{
  width: 25.2rem !important;
}
}
@media(min-width: 700px){
.cart-bottom{
  width: 42.2rem !important;
}
}
@media (min-width: 770px) {
.menudata{
  z-index: 1;
  height: 38.8rem;
  margin-top: 4% !important;
}
.userDetails{
  margin-top: 6% !important;
}
.menudata1{
  height: 13rem !important;
}
.lgcart{
  height: 16rem !important;
}
.cart{
  margin-top: 4% !important;
}
.cart-bottom{
  width: 29rem !important;
}
}
@media(min-width:800px){
.menudata1{
  height: 12.5rem !important;
}
.cart-bottom{
  width: 49rem !important;
}
}
@media(min-width:900px){
.menudata1{
  height: 15rem !important;
}
}
@media (min-width: 1200px) {
.maindata{
  overflow-y: hidden;
  height: 100vh;
}
.userDetails{
  margin-top: 2.4% !important;
}
.menudata{
  z-index: 1;
  height: 39rem;
  margin-top: 2% !important;
  margin-left: 0.5% !important;
}
.lgcart{
  height: 17rem !important;
}
.menudata1{
  height: 13rem !important;
}
.cart{
  margin-top: 2% !important;
}
}
@media (min-width: 1400px) {
.maindata{
  overflow-y: hidden;
}
.userDetails{
  margin-top: 2.4% !important;
}
.cart-bottom{
  width: 23.5rem !important;
}
}
@media (min-width: 1900px) {
.maindata{
  overflow-y: hidden;
}
.userDetails{
  margin-top: 2.5% !important;
}
.menudata{
  z-index: 1;
  height: 45rem;
  margin-top: 3% !important;
  margin-left: 0.5% !important;
}
.lgcart{
  height: 20.3rem !important;
}
}