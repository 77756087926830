.table-container {
  position: relative;
  max-height: 80vh; /* Adjust this value as needed */
  overflow-y: auto;
  margin-top: 40px !important; /* Adjust this value based on the height of your navbar */
  margin-right: 1.5% !important;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f2994a; /* Ensure the header background matches your design */
}

.table th,
.table td {
  padding: 12px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.table th {
  color: #0F1419;
  text-transform: uppercase;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tr:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}

.table ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.table li {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .table th,
  .table td {
    white-space: nowrap;
  }
}
@media(min-width :0px){
  .table-container-1 {
    margin-top: 18.5% !important; /* Adjust this value based on the height of your navbar */
  }
}
@media (min-width: 700px){
  .table-container-1 {
    margin-top: 10.5% !important; /* Adjust this value based on the height of your navbar */
  }
}
@media (min-width: 1000px){
  .table-container-1 {
    margin-top: 6.5% !important; /* Adjust this value based on the height of your navbar */
  }
}
@media (min-width: 1200px){
  .table-container-1 {
    margin-top: 1.5% !important; /* Adjust this value based on the height of your navbar */
  }
}

.dialog-title {
  background-color: #f2994a;
  color: #fff;
  padding: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.dialog-content {
  padding: 24px;
  font-family: 'Poppins', sans-serif;
}

.label {
  font-weight: bold;
  margin-right: 5px;
}

.value {
  font-weight: normal;
  color: #333;
}

/* Container for the bill */
.bill-container {
  height: fit-content;
  width: 30%;
  border-radius: 8px;
  padding-right: 20%;
  padding-left: 20%;
  border-radius: 8px;
}

.bill-container h2{
  text-align: center;
  margin-bottom: 10px;
}
.bill-container p {
  text-align: left;
  margin-bottom: 10px;
}
.bill-container table {
  width: 90%;
  border-collapse: collapse;
  margin: 20px 0;
}

.bill-container th, .bill-container td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}



.bill-container hr {
  border: 1px dashed #0b0b0b;
  margin: 10px 0;
}

.bill-container .thank-you {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  color: #007bff;
}

/* Responsive design */
@media (max-width: 600px) {
  .bill-container {
      width: 100%;
      padding: 10px;
  }

  .bill-container h2, .bill-container p {
      font-size: 1em;
  }

  .bill-container table {
      font-size: 0.9em;
  }
}



