@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.sidebar-container {
  position: relative;
  font-family: 'Poppins', sans-serif;
}

.sidebar {
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    font-family: 'Poppins', sans-serif;
    
  }

.sidebar.mobile {
  left: -220px;
  transition: left 0.3s ease;
  z-index: 999;
}

.sidebar.open {
  left: 0;
}

.sidebar-header {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar-header h2 {
  font-weight: 600;
}
.sidebar-header :hover {
  cursor: pointer;
}
.sidebar-header h2 span:first-child {
  color: #000;
}

.sidebar-header h2 span:last-child {
  color: #0093FB;
}

.sidebar-toggle {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.sidebar-options {
  display: flex;
  flex-direction: column;
}

.sidebar-option {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.5s ease,color 0.5s ease;
  margin-bottom: 12px;
  font-family: 'Poppins', sans-serif;
}

.sidebar-option:hover {
  background-color: #0093FB;
  color: #fff;
}

.sidebar-option.selected {
  background-color: #0093FB;
  color: #fff;
}

.sidebar-option i {
  margin-right: 10px;
}

.sidebar-toggle-mobile {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1;
  font-size: 24px;
  padding: 0;
}

.sidebar-toggle-mobile.open {
  right: 220px; /* Adjust this value based on the width of your sidebar */
}

.sidebar-option i,
.sidebar-option .icon-reserve,
.sidebar-option .icon-kot-history,
.sidebar-option .icon-order-history,
.sidebar-option .icon-edit-layout,
.sidebar-option .icon-edit-menu {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  transition: width 0.5s ease, height 0.5s ease;
}

@media (min-width: 0px) {
    .sidebar {
      width: 16%;
      height: 100vh;
    }
    .sidebar-header h2 {
      font-size: 1.2rem; /* Adjust the font size for smaller screens */
    }
    .sidebar-option svg {
      width: 20px; /* Adjust the icon size for smaller screens */
      height: 20px;
    }
    
    
  }

@media (max-width: 1199px) {
  .sidebar {
    width: auto;
    height: 100vh;
  }
  .sidebar-header h2 {
    font-size: 1.2rem; /* Adjust the font size for smaller screens */
  }
  .sidebar-option svg {
    width: 20px; /* Adjust the icon size for smaller screens */
    height: 20px;
  }
  
  
}


@media (min-width: 1200px) {
    .sidebar {
        width: 16%;
        height: 100vh;
    }
    .sidebar-header h2 {
      font-size: 1.5rem; /* Adjust the font size for smaller screens */
    }
    .sidebar-option svg {
      width: 15px; /* Adjust the icon size for smaller screens */
      height: 15px;
    }
    .sidebar-option {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        border-radius: 10px;
        transition: background-color 0.5s ease,color 0.5s ease;
        margin-bottom: 12px;
        font-family: 'Poppins', sans-serif;
        font-size: 1.3rem;
      }
      .sidebar-option i,
      .sidebar-option .icon-reserve,
      .sidebar-option .icon-kot-history,
      .sidebar-option .icon-order-history,
      .sidebar-option .icon-edit-layout,
      .sidebar-option .icon-edit-menu {
        margin-right: 10px;
        width: 28px;
        height: 28px;
        transition: width 0.5s ease, height 0.5s ease;
      }
      
  }
  @media (min-width: 1400px) {
    .sidebar {
        width: 16%;
        height: 100vh;
    }
    .sidebar-header h2 {
      font-size: 1.5rem; /* Adjust the font size for smaller screens */
    }
    .sidebar-option svg {
      width: 15px; /* Adjust the icon size for smaller screens */
      height: 15px;
    }
    .sidebar-option {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        border-radius: 10px;
        transition: background-color 0.5s ease,color 0.5s ease;
        margin-bottom: 12px;
        font-family: 'Poppins', sans-serif;
        font-size: 1.2rem;
      }
      .sidebar-option i,
      .sidebar-option .icon-reserve,
      .sidebar-option .icon-kot-history,
      .sidebar-option .icon-order-history,
      .sidebar-option .icon-edit-layout,
      .sidebar-option .icon-edit-menu {
        margin-right: 10px;
        width: 28px;
        height: 28px;
        transition: width 0.5s ease, height 0.5s ease;
      }
      
  }
  @media (min-width: 1800px) {
    .sidebar {
      width: 16%;
      height: 100vh;
    }
    .sidebar-header h2 {
      font-size: 1.7rem; /* Adjust the font size for smaller screens */
    }
    .sidebar-option svg {
      width: 20px; /* Adjust the icon size for smaller screens */
      height: 20px;
    }
    .sidebar-option {
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        border-radius: 10px;
        transition: background-color 0.5s ease,color 0.5s ease;
        margin-bottom: 12px;
        font-family: 'Poppins', sans-serif;
        font-size: 1.5rem;
      }
      .sidebar-option i,
      .sidebar-option .icon-reserve,
      .sidebar-option .icon-kot-history,
      .sidebar-option .icon-order-history,
      .sidebar-option .icon-edit-layout,
      .sidebar-option .icon-edit-menu {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        transition: width 0.5s ease, height 0.5s ease;
      }
      
  }

  .mobile-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px; /* Adjust as needed */
    background-color: #ffffff; /* White background */
  }
  
  .mobile-header-container h2 {
    margin: 0;
    font-size: 20px; /* Adjust the font size as needed */
  }
  
  .mobile-header-container span {
    color: #0093fb; /* Blue color for "Dost" */
  }
  
  .mobile-header-container span:first-child {
    color: #000000; /* Black color for "Dine" */
    margin-right: 5px; /* Adjust the spacing between "Dine" and "Dost" */
  }
  