/* General Customertable styling */
.Customertable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .Customertable th, .Customertable td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  /* Mobile responsive styling */
  @media screen and (max-width: 768px) {
    .customerOrder{
      margin-left: 3%;
      margin-right: 3%;
    }
    .Customertable, .Customertable thead, .Customertable tbody, .Customertable th, .Customertable td, .Customertable tr {
      display: block;
      width: 97%;
      margin-left: 2%;
      margin-right: 2%;
    }
  
    .Customertable thead tr {
      display: none; /* Hide Customertable headers */
    }
  
    .Customertable tr {
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
    }
  
    .Customertable td {
      display: flex;
      justify-content: space-between;
      padding-left: 50%;
      position: relative;
      text-align: left;
      border-bottom: none;
    }
  
    .Customertable td::before {
      content: attr(data-label);
      position: absolute;
      left: 10px;
      font-weight: bold;
      text-transform: capitalize;
    }
  
    /* Optional: Add background color for better separation of rows */
    .Customertable tr:nth-child(odd) {
      background-color: #f9f9f9;
    }
  }
  