@media (max-width: 435px) {
    .username{
        font-size: 20px !important;
    }
    .size{
        font-size: 10px !important;
    }
    .logo{
        width: 20% !important;
    }
    .language{
        margin-right: 3% !important;
    }
  }

  .logo{
    width: 7%;
  }
  .logo:hover{
    cursor: pointer;
  }