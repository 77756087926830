

.elapsed-time {
  display: inline-block;
  width: 80px; /* Set a fixed width to prevent layout shifts */
  text-align: center; /* Center the text */
  padding: 5px;
  border-radius: 5px;
  transition: color 0.5s, transform 0.5s;
}

.orange {
  color: orange;
  animation: breathe 2s infinite;
}

.red {
  color: red;
  animation: breathe 1s infinite;
}

@keyframes breathe {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
